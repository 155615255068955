import React from "react";

import LoginIcon from "@mui/icons-material/Login";
import { Box, Button, Typography } from "@mui/material";

export default function FinishedComponent() {
  return (
    <Box sx={{ textAlign: "center", my: 5 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "18px", mb: 2 }}>
        منصة الشكاوى متاحة الآن
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          window.location.reload();
          //   window.location.href = "/";
        }}
        startIcon={<LoginIcon />}
      >
        <span style={{ padding: "5px", fontWeight: "bold" }}>
          الذهاب إلى التسجيل{" "}
        </span>
      </Button>
      <Typography sx={{ fontWeight: "bold", mt: 2 }}>
        يمكن الآن تسجيل الدخول للمنصة بإدراج البيانات الخاصة بك.
      </Typography>
    </Box>
  );
}
