import { createTheme, ThemeProvider } from "@mui/material";

import LogoAppBar from "./Components/LogoAppBar";
import Counter from "./Pages/Counter";

function App() {
  const theme = createTheme({
    direction: "rtl",
    typography: {
      fontFamily: ["Open Sans", "Roboto"].join(","),
    },
    palette: {
      primary: {
        // main: "#00582E",
        oldMain: "#0a6a79",
        main: "#0a6a79",
        studentTabsBackground: "#f1f2f3",
      },
    },
  });

  window.onerror = function (message, source, lineno, colno, error) {
    console.error("Global error:", message, error);
    // Optionally show a user-friendly message
  };

  window.onunhandledrejection = function (event) {
    console.error("Unhandled promise rejection:", event.reason);
    // Optionally show a user-friendly message
  };

  return (
    <ThemeProvider theme={theme}>
      <div dir="rtl">
        <LogoAppBar />

        <Counter />
      </div>
    </ThemeProvider>
  );
}

export default App;
