import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import TimerComponent from "./TimerComponent";
import FinishedComponent from "./FinishedComponent";

const targetDate = "2025-01-20T07:00:00";

const Countdown = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();

    let timeLeft = {};

    // console.log({ difference });
    if (difference > 0) {
      timeLeft = {
        ثوانى: Math.floor((difference / 1000) % 60),
        دقائق: Math.floor((difference / 1000 / 60) % 60),
        ساعات: Math.floor((difference / (1000 * 60 * 60)) % 24),
        أيام: Math.floor(difference / (1000 * 60 * 60 * 24)),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          p: { md: 4, xs: 2 },
          m: { md: 1, xs: 0.5 },
          display: "inline-block",
          width: { md: "100px", xs: "32px" },
          fontSize: { md: "16px", xs: "14px" },
          borderRadius: "15px",
        }}
        key={interval}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", fontSize: { md: "30px", xs: "16px" } }}
        >
          {interval}{" "}
        </Typography>
        <Typography
          variant="h1"
          sx={{ fontWeight: "bold", fontSize: { md: "45px", xs: "30px" } }}
        >
          {timeLeft[interval]}
        </Typography>
      </Box>
    );
  });

  return (
    <Box sx={{ m: { lg: 5, xs: 2 }, textAlign: "center" }}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
        أهلا بك فى منصة الشكاوى
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        كلية إدارة الأعمال - جامعة الملك فيصل
      </Typography>

      {timerComponents.length ? (
        <TimerComponent timerComponents={timerComponents} />
      ) : (
        <FinishedComponent />
      )}

      <Typography sx={{ fontWeight: "bold" }}>
        ومن ثم إضافة المشكلة التى تواجهك, وسيتم الرد عليها فى أقرب وقت.{" "}
      </Typography>

      <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
        نشكركم لأستخدامكم منصة الشكاوى - كلية إدارة الأعمال - جامعة الملك فيصل{" "}
      </Typography>
    </Box>
  );
};
export default Countdown;
