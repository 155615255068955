import { Box, Typography } from "@mui/material";
import React from "react";

export default function TimerComponent({ timerComponents }) {
  return (
    <Box sx={{ textAlign: "center", my: 5 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
        متبقى من الوقت لأفتتاح المنصة :
      </Typography>
      {timerComponents}

      <Typography sx={{ fontWeight: "bold", mt: 3 }}>
        بعدها يمكن تسجيل الدخول للمنصة بإدراج البيانات الخاصة بك.
      </Typography>
    </Box>
  );
}
